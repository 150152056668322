import { useEffect } from 'react';
import React from "react"
import { MapContainer, TileLayer } from "react-leaflet"
import "./Map.css"
import * as L from "leaflet"
import { GestureHandling } from "leaflet-gesture-handling"


import "leaflet/dist/leaflet.css"
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css"

const proj4 = typeof window !== `undefined` ? require("proj4leaflet") : null

function useConfigureLeaflet() {
  useEffect(() => {
    // To get around an issue with the default icon not being set up right between using React
    // and importing the leaflet library, we need to reset the image imports
    // See https://github.com/PaulLeCam/react-leaflet/issues/453#issuecomment-410450387

    delete L.Icon.Default.prototype._getIconUrl;

    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require( 'leaflet/dist/images/marker-icon-2x.png' ),
      iconUrl: require( 'leaflet/dist/images/marker-icon.png' ),
      shadowUrl: require( 'leaflet/dist/images/marker-shadow.png' ),
    });
  }, []);
}

const Map = props => {
  const { children, bounds, ...rest } = props

  useConfigureLeaflet();

  /* Required by gatsby build, works fine witohut in develop since window is defined browser? */
  if (typeof window !== "undefined") {
    // Setup the EPSG:27700 (British National Grid) projection.
    var crs = new proj4.CRS(
      "EPSG:27700",
      "+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +ellps=airy +towgs84=446.448,-125.157,542.06,0.15,0.247,0.842,-20.489 +units=m +no_defs",
      {
        resolutions: [
          896.0,
          448.0,
          224.0,
          112.0,
          56.0,
          28.0,
          14.0,
          7.0,
          3.5,
          1.75,
        ],
        origin: [-238375.0, 1376256.0],
      }
    )

    const mapSettings = {
      bounds: bounds,
      crs: crs,
      minZoom: 0,
      maxZoom: 9,
      attributionControl: true,
      gestureHandling: true,
      ...rest,
    }

    L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling)

    const attributionText = '© Crown copyright and database rights ' + new Date().getFullYear()

    return (
      <MapContainer {...mapSettings}>
        {children}
        <TileLayer
          attribution={attributionText}
          url="https://api.os.uk/maps/raster/v1/zxy/Leisure_27700/{z}/{x}/{y}.png?key=4O65KRx7pDwdZq98W173UDKX1OSUNOAq"
        />
      </MapContainer>
    )
  }
  return null
}

export default Map